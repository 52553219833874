
import RaavyaHome from '../Assets/Works/Ui-Raavya/img1.png'
import RaavyaMob from '../Assets/Works/Ui-Raavya/imgmob.png'
import img4 from '../Assets/Works/Ui-POH/img4.png';
import imgpoh from '../Assets/Works/Ui-POH/imgpoh.png';
import imgepehe from '../Assets/Works/ephe/imgephe.png' ; 
import imgephemob from '../Assets/Works/ephe/imgepehemob.png' ; 


export const CaseStudyData = [
    {
        id: 1,
        tagline: "Sustainable Home Decor and Furniture Solutions",
        introduction: "The Raavya  is a premium brand focusing on eco-friendly, innovative furniture and decor solutions. This project involved designing a user-friendly interface to enhance brand accessibility and aesthetics.",
        clientInfo: {
            name: "TheRaavya : Your Home Creator",
            description: "A boutique home decor and furniture brand based in New Delhi, specializing in premium and sustainable designs.",
            location: "New Delhi, India",
        },
        challenges: [
            "Building a seamless online platform.",
            "Highlighting the premium and sustainable nature of the brand.",
            "Making the platform visually engaging yet easy to navigate."
        ],
        objectives: [
            "Create a responsive and visually appealing design.",
            "Showcase the client’s product range effectively.",
            "Ensure scalability for future features like bulk orders and customized inquiries."
        ],
        process: {
            research: "Conducted user persona analysis, competitor benchmarking, and market trend studies.",
            wireframing: "Designed low-fidelity prototypes to visualize key components and user flow.",
            design: "Developed a sleek, modern UI using tools like Figma and incorporated branding elements.",
            testing: "Conducted usability testing and iterated designs based on feedback."
        },
        solutions: [
            "Developed an interactive product catalog.",
            "Incorporated a bulk order feature for B2B clients.",
            "Implemented a responsive design optimized for all devices."
        ],
        results: {
            engagement: "50% increase in user engagement post-launch.",
            inquiries: "30% boost in online inquiries within the first month.",
            feedback: "Positive reviews from both the client and end-users."
        },
        keyFeatures: [
            "Dynamic product showcase.",
            "Interactive bulk order feature.",
            "Mobile-first responsive design."
        ],
        visuals: [
            {
                imageUrl: RaavyaHome,
                description: "Screenshot of the homepage showcasing the hero section."
            },
            {
                imageUrl: RaavyaMob,
                description: "Mobile view of the product catalog."
            }
        ],
        clientTestimonial: "Working with this team has been a game-changer for our business. Their dedication and creativity are unmatched!",
        callToAction: {
            viewProjectLink: "http://theraavya.in/",
           
        }
    },
    {
        id: 2,
        tagline: "Premium Handcrafted Pottery for Every Home",
        introduction: "Pots of Happiness is a boutique pottery studio specializing in unique, handcrafted pottery pieces that blend artistry and functionality. This project focused on building a visually rich, user-friendly platform to showcase their premium pottery collection and drive customer engagement.",
        clientInfo: {
            name: "Pots of Happiness",
            description: "A boutique pottery studio offering handcrafted pottery pieces, known for their artistic designs and premium quality.",
            location: "Jaipur, India",
        },
        challenges: [
            "Showcasing intricate, handcrafted details effectively online.",
            "Creating an immersive visual experience while maintaining performance.",
            "Ensuring a smooth user journey for browsing pottery items."
        ],
        objectives: [
            "Design a visually immersive and clean interface to highlight the craftsmanship.",
            "Develop an interactive catalog to showcase pottery collections effectively.",
            "Build a scalable platform to support future product launches and user inquiries."
        ],
        process: {
            research: "Analyzed customer expectations, market trends in pottery , ceramics, and competitor strategies.",
            wireframing: "Created low-fidelity wireframes to map out user flow and key touchpoints.",
            design: "Crafted a minimalistic, art-inspired UI using Figma, focusing on visual storytelling and branding.",
            testing: "Conducted A/B testing and gathered user feedback to optimize the experience."
        },
        solutions: [
            "Developed an elegant studio section with features to highlight intricate details.",
            "Ensured a responsive design optimized for both mobile and desktop users."
        ],
        results: {
            engagement: "40% increase in user engagement within the first month.",
            inquiries: "25% rise in customized order inquiries post-launch.",
            feedback: "The platform received praise for its aesthetics and user-friendliness from customers and stakeholders."
        },
        keyFeatures: [
            "Immersive product display with immersive experience.",
            "Clean, art-inspired interface tailored for premium handcrafted pottery.",
            "Mobile-friendly responsive design for an optimal user experience."
        ],
        visuals: [
            {
                imageUrl: img4,
                description: "Screenshot of the homepage showcasing the Landing Page"
            },
            {
                imageUrl: imgpoh,
                description: "Mobile view displaying individual pottery items with description."
            }
        ],
        clientTestimonial: "Exceptional service and attention to detail! They brought our vision to life in ways we couldn’t have imagined.",
        callToAction: {
            viewProjectLink: "https://potsofhappiness-demo.vercel.app/",
        }
    },
    {
        id: 3,
        tagline: "Bold, Elevated Design Pieces for Celebrating Life's Ephemeral Moments",
        introduction: "Ephemeral Vidp is a design studio specializing in crafting bold, elevated design pieces that celebrate fleeting moments and experiences. This project revolved around creating an engaging, artistic platform to showcase their unique designs while emphasizing their core ethos of capturing the beauty of the ephemeral.",
        clientInfo: {
            name: "Ephemeral Vidp",
            description: "A cutting-edge design studio known for creating elevated design pieces that reflect bold artistry and fleeting moments.",
            location: "New Delhi, India",
        },
        challenges: [
            "Communicating the concept of 'ephemeral design' effectively to a diverse audience.",
            "Creating a striking, bold visual identity while ensuring usability.",
            "Balancing artistic expression with functional navigation."
        ],
        objectives: [
            "Design a platform that embodies the bold and elevated ethos of the brand.",
            "Highlight the unique storytelling behind each design piece.",
            "Ensure seamless navigation to enhance user experience and engagement."
        ],
        process: {
            research: "Conducted in-depth research on design trends, audience expectations, and competitors in the premium design space.",
            wireframing: "Developed wireframes focusing on bold aesthetics and intuitive navigation.",
            design: "Designed a dynamic, visually compelling interface in Figma, blending bold typography, vibrant imagery, and minimalistic layouts.",
            testing: "Performed usability testing with target users and iterated based on feedback to refine the experience."
        },
        solutions: [
            "Created a visually striking interface with bold visuals and minimalistic layouts.",
            "Integrated a storytelling feature to showcase the inspiration behind each design piece.",
            "Optimized for responsive design, ensuring a consistent experience across all devices."
        ],
        results: {
            engagement: "35% increase in time spent on the website within the first month.",
            inquiries: "30% growth in inquiries about custom design collaborations.",
            feedback: "Widely praised for its bold visual identity and seamless user experience by customers and critics."
        },
        keyFeatures: [
            "Immersive storytelling for each design piece, blending visuals and narrative.",
            "Dynamic, bold interface with elevated design aesthetics.",
            "Responsive and mobile-friendly design optimized for all devices."
        ],
        visuals: [
            {
                imageUrl: imgepehe,
                description: "Screenshot of the homepage showcasing bold visuals and storytelling elements."
            },
            {
                imageUrl: imgephemob,
                description: "Mobile view of the gallery section displaying individual design pieces."
            }
        ],
        clientTestimonial: "Their professionalism and innovative approach exceeded our expectations. Highly recommend their services!",
        callToAction: {
            viewProjectLink: "https://www.ephemeralvidp.in/"
        }
    }
    
    

];
